import {mapMutations, mapGetters, mapActions} from "vuex";

export default {
    name: "constructor",
    props: {
        item: {
            type: Object,
            default: () => {
            }
        },

    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            constructorsData: 'constructors/constructors',
            page: 'constructors/constructorsData'
        })
    },
    created() {
        this.fetchConstructors()
        this.fetchConstructorPage()
    },
    methods: {
        ...mapMutations({}),
        ...mapActions({
            fetchConstructors: 'constructors/GET_CONSTRUCTORS',
            fetchConstructorPage: 'constructors/GET_CONSTRUCTORS_PAGE',
        })
    }
}
